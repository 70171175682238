import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    video:{
        margin: '0 auto',
        textAlign: 'center',
    },
    iframe:{
        width:'85%',
        margin:'.4rem 0rem 1rem 2rem',
        height:'30rem',
        [theme.breakpoints.down('md')]: {
            width:'85%',
            height:'50vw',
        },
        [theme.breakpoints.down('xs')]: {
            width:'85%',
            height:'auto',
            margin:' .4rem 0rem 1rem 0rem',
        },
    },
}));

export default function Video({ videoSrcURL, videoTitle, ...props }){
    const classes = useStyles();

    return (
        <div className={classes.video}>
            <iframe
                className={classes.iframe}
                src={videoSrcURL}
                title={videoTitle}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
            />
        </div>
    );
};

